<template>
  <div class="page mrtop">
    <div class="page-header">
      <div class="header-layer">
        <div class="header-title">
          <h3>Дүрүүд</h3>
          <span class="divider"></span>
          <p>Цувралын дүрийн мэдээлэл</p>
        </div>

        <div class="header-breadcrumb">
          <router-link to="/">
            <span>Эхлэл</span>
            <i class="isax isax-arrow-right-4"></i>
          </router-link>

          <router-link to="" class="current">
            <span>Дүрүүд</span>
          </router-link>
        </div>
      </div>
    </div>

    <div class="w-90 h-full md:pb-44 seeroo-media seeroo-character flex flex-col lg:flex-row">
      <div class="relative character-page">
        <div class="seeroo-cat">
          <div class="seeroo-cat-box">
            <div class="seeroo-cat-box-header">
              <i class="isax isax-emoji-happy"></i>
              <h2>Дүрийн төрлүүд</h2>
            </div>
            <div class="cat-list cat-list-seeroo">
              <router-link to="/character/all" :class="`btn btn-sm m-1 ${$route.params.cat == 'all' ? 'active' : ''}`">
                Бүх дүр
              </router-link>

              <router-link :to="`/character/${item.id}`" v-for="item in types" :key="item.id"
                           :class="`btn btn-sm btn-character m-1 ${$route.params.cat == item.id ? 'active' : ''}`">
                <div class="title">{{ item.title }}</div>
                <div class="bg-character-layer" :style="`background: url(${imageUrl(item.image)})`"></div>
              </router-link>
            </div>
          </div>
        </div>
      </div>

      <div class="seeroo-cat-box">
        <div class="seeroo-cat-box-header border-b-[0px] visible lg:hidden flex justify-between">
          <div class="flex justify-between">
            <i class="isax isax-emoji-happy"></i>
            <h2>Дүрийн төрлүүд</h2>
          </div>
          <Button label="Сонгох" class="visible lg:hidden" @click="showActorsModal"/>
        </div>
      </div>
      <div class="seeroo-character-page">
        <router-view :key="$route.fullPath"/>
      </div>
    </div>
  </div>
  <Dialog :showHeader="false" class="auth-modal" v-model:visible="isActorsTypes" :modal="true" :dismissableMask="true"
          position="center" :closable="true" :style="{width: '240px'}">
    <div class="relative">
      <div class="seeroo-cat">
        <div>
          <div class="seeroo-cat-box-header flex justify-between">
            <div class="flex">
              <i class="isax isax-emoji-happy"></i>
              <h2>Дүрийн төрлүүд</h2>
            </div>
            <i class="isax isax-close-circle" @click="showActorsModalHide"></i>
          </div>
          <div class="cat-list cat-list-seeroo">
            <router-link to="/character/all" :class="`btn btn-sm m-1 ${$route.params.cat == 'all' ? 'active' : ''}`" @click="showActorsModalHide">
              Бүх дүр
            </router-link>

            <router-link :to="`/character/${item.id}`" v-for="item in types" :key="item.id"
                         :class="`btn btn-sm btn-character m-1 ${$route.params.cat == item.id ? 'active' : ''}`" @click="showActorsModalHide">
              <div class="title">{{ item.title }}</div>
              <div class="bg-character-layer" :style="`background: url(${imageUrl(item.image)})`"></div>
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </Dialog>
</template>

<script>
import {imageUrl} from "../../utils";
import api from "../../store/_boot/baseApi"

export default {
  components: {},
  setup() {

  },
  data() {
    return {
      types: [],
      characters: [],
      isActorsTypes: false,
    }
  },

  computed: {},

  created() {
    this.getCharacterTypes();
  },

  watch: {},

  methods: {
    imageUrl,

    getCharacterTypes() {
      api.get('/api/m/character/type').then(({data}) => {
        this.types = data;
      })
    },

    showActorsModal() {
      this.isActorsTypes = true;
    },

    showActorsModalHide() {
      this.isActorsTypes = false;
    },
  }
};
</script>
<style>
</style>